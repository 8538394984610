.leiloes {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100px;
    background-color: #d4cec9;
    margin-top: 22px;
    margin-bottom: 16px;
    padding: 8px 0 8px 32px ;
    gap: 32px;
}

.leiloes img {
    height: 70%;
}

.tipos {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.cards {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 400px;
    margin: 4px;
    border-radius: 5px;
    background-color: #fff;
}

.spacer {
    margin-bottom: 16px;
}

.test {
    color: #ff9532;
}